<template>
  <div>
    <Breadcrumbs main="" title="Referral program" />
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="p-4">
            <b-row>
              <b-col>
                  <div class="p-5 b-r-10" slot="with-padding" style="background-color: #f6f5df;">
                    <b-row class="f-20">
                      <b-col class="text-center">
                        <div class="f-w-900">{{ referCounts.registerUserCount }}</div>
                        <div>Registered users</div>
                      </b-col>
                      <b-col class="text-center">
                        <div class="f-w-900">{{ referCounts.payingUserCount }}</div>
                        <div>Paying users</div>
                      </b-col>
                      <b-col class="text-center">
                        <div class="f-w-900">${{ referCounts.paybleAmount }}</div>
                        <div>Earnings to be paid</div>
                      </b-col>
                    </b-row>

                    <b-row class="mt-5">
                        <b-col cols="12" class="text-center">
                            <center>
                            <div class="col-md-8 col-12" @click="copyUrl()"><b-input class="text-center" :class="isCopied?'text-success':'text-primary'" v-model="refurl" disabled></b-input></div>
                            </center>
                        </b-col>
                        <b-col cols="12" class="text-center">
                            <small
                            >Copy this link to referer the user and earn a
                            commission</small
                          >
                        </b-col>
                        <b-col cols="12" class="text-center mt-3">
                        <b-button :variant="isCopied?'success':'primary'" @click="copyUrl()" :class="{ copied: isCopied }">
                            <span v-if="!isCopied">Copy</span>
                            <span v-else>Copied!</span></b-button>
                      </b-col>
                    </b-row>
                  </div>
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col>
                  <div class="p-5 b-r-10" slot="with-padding" style="background-color: #f5f6f9;">
                    <h2 class="f-w-900">Frequently Asked Questions</h2>
                    <b-row>
                      <b-col>
                        <div class="mt-4">
                          <h4 class="f-w-900">&#8226; What is the referral program?</h4>
                          <p class="f-14">
                            The referral program is a way to earn money by
                            referring users to PingMonk. You will get a
                            commission for every user you refer to PingMonk.
                          </p>
                        </div>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <div class="mt-4">
                          <h4 class="f-w-900">&#8226; How does it work?</h4>
                          <p class="f-14">
                            You will get a referral link. You can share this
                            link with your friends, family, colleagues, etc.
                            When they sign up for PingMonk using your referral
                            link, you will get a commission.
                          </p>
                        </div>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <div class="mt-4">
                          <h4 class="f-w-900">&#8226; What is the commission rate?</h4>
                          <p class="f-14">
                            <strong
                              >You will get a 20% LIFETIME (recurring)
                              commission</strong
                            >
                            from each full price payment made by a referred user
                            who registers and then subscribes through the link
                            with your unique affiliate ID. In case of a
                            discounted price, the commission is reduced by the
                            percentage amount of the discount.
                          </p>
                        </div>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <div class="mt-4">
                          <h4 class="f-w-900">&#8226; How about cookies expiration?</h4>
                          <p class="f-14">
                            <strong>Cookies are valid for 30 days,</strong> so
                            the referred user needs to register the PingMonk
                            account within 30 days after clicking on the link
                            with your affiliate ID. If the user subscribes to
                            one of the paid plans later, you will get the
                            commission either way. Keep in mind that if the user
                            registers by using a link with someone else's
                            affiliate ID or without it and subscribes later by
                            using your affiliate ID link, you will not earn the
                            commission.
                          </p>
                        </div>
                      </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <div class="mt-4">
                            <h4 class="f-w-900">&#8226; When do I get paid?</h4>
                            <p class="f-14">
                                The commissions are paid once a month via PayPal. You can request the payout upon reaching a minimum threshold of $100. Keep in mind that we reserve the right to postpone the payout until at least two different users are referred to avoid self-referrals.
                            </p>
                            </div>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <div class="mt-4">
                            <h4 class="f-w-900">&#8226; Are there any restricted affiliate activities?</h4>
                            <p class="f-14">
                                As we want to keep our Affiliate Program fair, there are some restricted activities:
                                <ul>
                                    <li>&#8226; Coupon sites</li>
                                    <li>&#8226; PPC advertising with a direct link leading to any PingMonk website (including all the tools and landing pages)</li>
                                    <li>&#8226; Using any PingMonk brand, logo or name of the tools or their misspelled versions in PPC advertising, in domains, subdomains, or in profiles on social media</li>
                                    <li>&#8226; Using misleading or incorrect information (non-existent discounts, etc.)</li>
                                    <li>&#8226; Using discount coupons that are not assigned to you</li>
                                    <li>&#8226; The so-called 'self-referrals' (when you create another account while referring yourself)</li>
                                </ul>
                            </p>
                            </div>
                        </b-col>
                    </b-row>
                  </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import  Config  from "../../services/config";
import UserController from "../../services/userController";
export default {
  name: "Referral",
  data() {
    return {
      refurl: "",
      userinfo: {},
      referCounts:{ registerUserCount: 0,payingUserCount: 0,paybleAmount: 0},
      isCopied: false
    };
  },
  mounted() {
    this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
    this.refurl = Config.refUrl+'?refererid='+this.userinfo.userid;
    this.GetRefCounts()
  },
  methods:{
    async GetRefCounts(){
        let response = await UserController.getRefCount();
        if(response.result){
            this.referCounts = response.data;
        }
        else{
            this.$toasted.show(response.message, {
            theme: "outline",
            position: "bottom-center",
            type: "error",
            duration: 2000,
          });
        }
    },
    copyUrl(){
        this.isCopied = true;
        setTimeout(() => {
            this.isCopied = false;
        }, 2000);
          const el = document.createElement('textarea');
          el.addEventListener('focusin', e => e.stopPropagation());
          el.value = this.refurl;
          document.body.appendChild(el);
          el.select();
          document.execCommand('copy');
          document.body.removeChild(el);
          this.$toasted.show('URL copied to clipboard.', {theme: 'outline',position: "bottom-center", type: 'success', duration: 2000});
        },
  }
};
</script>